@font-face {
  font-family: "Bagnard";
  src: local("Bagnard"), url(./assets/fonts/Bagnard.otf) format("truetype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-2 py-2 mx-4 text-lg rounded border min-w-1/6;
  }
  .btn:hover {
    @apply border bg-buttonHoverColor text-tcsBlueDark border-tcsBlueDark;
  }
  .btn-white {
    @apply bg-primary text-tcsBlueDark;
  }
  .btn-blue {
    @apply text-white bg-secondary;
  }

  .clearfix {
    @apply box-content clear-both;
  }
  .container {
    @apply px-0 px-3 py-4 mx-auto md:max-w-6xl;
  }
  .input-text {
    @apply p-3 mt-4 leading-none text-gray-900 bg-gray-100 rounded border border-gray-200 focus:outline-none focus:border-blue-700;
  }
  .content {
    @apply p-8 m-10 max-w-sm font-extralight text-gray-600 rounded;
  }
  .title {
    @apply mb-4 text-lg font-normal leading-4 uppercase decoration-0;
  }
  .input-group {
    @apply mb-4;
  }
  .input-group label {
    @apply block mb-2 text-xs;
  }

  .input-group label {
    @apply after:content-['*'] after:text-[#e15858] after:text-lg;
  }
  .input-group.submit {
    @apply text-right;
  }
  .input {
    @apply bg-primary border border-[#aaa] px-3 px-4 h-10  font-extralight text-xs w-full hover:border-[#151210];
  }
  input.btn.btn-submit {
    @apply bg-[#f4b400] mt-4 border border-black shadow-black shadow-lg text-black py-1 px-4 hover:bg-[#fac769];
  }
  .styleB.container {
    @apply bg-lightYellow;
  }
  .styleB {
    font-family: "Hurricane", cursive;
  }
  input.btn.btn-submit.styleB {
    @apply bg-[#000] text-white shadow hover:bg-lightYellow hover:text-black hover:shadow;
  }
  .styleC.container {
    background: linear-gradient(
      to right,
      theme("colors.rosePink"),
      theme("colors.lightRosePink")
    );
  }
  .styleC.container {
    @apply rounded-xl;
  }
  .styleC {
    font-family: "", cursive;
  }
  .title.styleC {
    @apply text-xl font-bold text-rosePink;
  }
  .input.styleC:focus {
    border: 1px solid #b42489;
  }
  input.btn.btn-submit.styleC {
    @apply bg-[#E40B79] hover:bg-rosePink;
  }
  .dark-ring {
    @apply dark:ring-1 dark:ring-inset dark:ring-white/10;
  }
}

@layer base {
  button {
    @apply outline-none;
  }
  h1,
  h2 {
    font-family: "Bagnard", serif;
  }
  a {
    @apply px-3 py-2 text-base font-bold no-underline rounded-md outline-none md:text-lg;
  }
  body {
    @apply text-gray-700 bg-primary;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    /* scrollbar-width: none; for Firefox */
    /* overflow-y: scroll; */
  }
  input:focus {
    @apply outline-none;
  }
  textarea:focus {
    @apply outline-none;
  }
}
